import React from "react";

import Giveaway from "./dashboard/giveaway";
import Withdraw from "./dashboard/withdraw";
import Mint from "./dashboard/mint";
import PriceChange from "./dashboard/pricechange";
import ToggleSale from "./dashboard/togglesale";
import ContractMetaUpdate from "./dashboard/contractmetaupdate";
import TokenMetaUpdate from "./dashboard/tokenmetaupdate";

import { getChain, targetChainId } from "../utils/web3";

const ContractStats = (props) => {
  const { loadStatus, contractDetails, w3mxf } = props;

  return (
    <div className="card w-full border">
      <div className="card-body items-center text-center">
        <h2 className="card-title flex-col text-2xl">
          <span>{loadStatus.fetching ? <div className="w-48 bg-base-content h-10 rounded-md animate-pulse "></div> : contractDetails.name}</span>
          <span>{loadStatus.fetching ? <div className="w-48 bg-base-content h-10 rounded-md animate-pulse "></div> : contractDetails.symbol}</span>
          <span>
            Contract:{" "}
            <a
              className="btn-link text-base-content"
              href={`${getChain(targetChainId).etherscan}address/${w3mxf._address}`}
              target="_blank"
              rel="noreferrer"
            >
              {w3mxf._address}
            </a>
          </span>
        </h2>
        <div className="stats shadow bg-primary text-primary-content stats-vertical lg:stats-horizontal w-fit lg:w-full mt-5">
          <div className="stat flex flex-col ">
            <div className="stat-title text-2xl font-bold">ETH Balance</div>
            <div className="stat-value ">
              {loadStatus.fetching ? (
                <div className="w-full bg-base-content h-10 rounded-md animate-pulse "></div>
              ) : (
                contractDetails.ethbalance + " ETH"
              )}
            </div>
            <div className="stat-actions">
              <Withdraw {...props} />
            </div>
          </div>
          <div className="stat flex flex-col ">
            <div className="stat-title text-2xl font-bold">Minted</div>
            <div className="stat-value ">
              {loadStatus.fetching ? (
                <div className="w-full bg-base-content h-10 rounded-md animate-pulse "></div>
              ) : (
                contractDetails.minted + " / " + contractDetails.total
              )}
            </div>
            <div className="stat-actions">
              <div className="flex flex-row justify-evenly">
                <Giveaway {...props} />

                <Mint {...props} />
              </div>
            </div>
          </div>
          <div className="stat flex flex-col ">
            <div className="stat-title text-2xl font-bold">Price</div>
            <div className="stat-value ">
              {loadStatus.fetching ? <div className="w-full bg-base-content h-10 rounded-md animate-pulse "></div> : contractDetails.price + " ETH"}
            </div>
            <div className="stat-actions">
              <PriceChange {...props} />
            </div>
          </div>
          <div className="stat flex flex-col ">
            <div className="stat-title text-2xl font-bold">Sale Status</div>
            <div className="stat-value capitalize ">
              {loadStatus.fetching ? (
                <div className="w-full bg-base-content h-10 rounded-md animate-pulse "></div>
              ) : contractDetails.salelive ? (
                "Started"
              ) : (
                "Not Started"
              )}
            </div>
            <div className="stat-actions">
              <ToggleSale {...props} />
            </div>
          </div>
        </div>
        {/*  <div className="stats shadow bg-primary text-primary-content flex w-full mt-5">
          <div className="stat flex flex-col ">
            <div className="stat-title text-2xl font-bold">Collection Info (for reflection on OpenSea)</div>
           <div className="stat-value  flex flex-col text-lg font-normal">
              {loadStatus.fetching ? (
                <div className="w-full bg-base-content h-10 rounded-md animate-pulse "></div>
              ) : (
                <>
                  {contractDetails?.contractmetadata?.name && (
                    <div className="flex flex-row w-full break-all ">
                      <div className="w-80 text-left break-all">Collection Name:</div>
                      <div className="w-full text-left break-all">{contractDetails?.contractmetadata?.name}</div>
                    </div>
                  )}
                  {contractDetails?.contractmetadata?.description && (
                    <div className="flex flex-row w-full break-all ">
                      <div className="w-80 text-left break-all">Description:</div>
                      <div className="w-full text-left break-all">{contractDetails?.contractmetadata?.description}</div>
                    </div>
                  )}
                  {contractDetails?.contractmetadata?.external_link && (
                    <div className="flex flex-row w-full break-all ">
                      <div className="w-80 text-left break-all">Website:</div>
                      <div className="w-full text-left break-all">
                        <a
                          className="btn-link text-primary-content"
                          href={contractDetails?.contractmetadata?.external_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {contractDetails?.contractmetadata?.external_link}
                        </a>
                      </div>
                    </div>
                  )}
                  {contractDetails?.contractmetadata?.seller_fee_basis_points && (
                    <div className="flex flex-row w-full break-all ">
                      <div className="w-80 text-left break-all">Royalties on OpenSea:</div>
                      <div className="w-full text-left break-all">{parseInt(contractDetails?.contractmetadata?.seller_fee_basis_points) / 100}%</div>
                    </div>
                  )}
                  {contractDetails?.contractmetadata?.fee_recipient && (
                    <div className="flex flex-row w-full break-all ">
                      <div className="w-80 text-left break-all">Royalties Wallet:</div>
                      <div className="w-full text-left break-all">
                        <a
                          className="btn-link text-primary-content"
                          href={`${getChain(targetChainId).etherscan}address/${contractDetails?.contractmetadata?.fee_recipient}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {contractDetails?.contractmetadata?.fee_recipient}
                        </a>
                      </div>
                    </div>
                  )}
                </>
              )}
                  </div>
            <div className="stat-actions">
              <ContractMetaUpdate {...props} contractMetadata={contractDetails.contractmetadata} />
            </div>
          </div>
        </div>*/}
        <div className="stats shadow bg-primary text-primary-content flex w-full mt-5">
          <div className="stat flex flex-col ">
            <div className="stat-title text-2xl font-bold">Token Metadata</div>
            <div className="stat-value  flex flex-col text-lg font-normal">
              {loadStatus.fetching ? (
                <div className="w-full bg-base-content h-10 rounded-md animate-pulse "></div>
              ) : (
                <>
                  {contractDetails?.tokenmetadata?.name && (
                    <div className="flex flex-row w-full break-all ">
                      <div className="w-80 text-left break-all">Name:</div>
                      <div className="w-full text-left break-all">{contractDetails?.tokenmetadata?.name}</div>
                    </div>
                  )}
                  {contractDetails?.tokenmetadata?.description && (
                    <div className="flex flex-row w-full break-all ">
                      <div className="w-80 text-left break-all">Description:</div>
                      <div className="w-full text-left break-all">{contractDetails?.tokenmetadata?.description}</div>
                    </div>
                  )}
                  {contractDetails?.tokenmetadata?.external_url && (
                    <div className="flex flex-row w-full break-all ">
                      <div className="w-80 text-left break-all">Website:</div>
                      <div className="w-full text-left break-all">
                        <a
                          className="btn-link text-primary-content"
                          href={contractDetails?.tokenmetadata?.external_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {contractDetails?.tokenmetadata?.external_url}
                        </a>
                      </div>
                    </div>
                  )}
                  {contractDetails?.tokenmetadata?.image && (
                    <div className="flex flex-row w-full break-all ">
                      <div className="w-80 text-left break-all">Image URL:</div>
                      <div className="w-full text-left break-all">
                        <a
                          className="btn-link text-primary-content"
                          href={`https://ipfs.infura.io/ipfs/${contractDetails?.tokenmetadata?.image?.split("//")[1]}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {contractDetails?.tokenmetadata?.image}
                        </a>
                      </div>
                    </div>
                  )}
                  {contractDetails?.tokenmetadata?.animation_url && (
                    <div className="flex flex-row w-full break-all ">
                      <div className="w-80 text-left break-all">Animation URL:</div>
                      <div className="w-full text-left break-all">
                        <a
                          className="btn-link text-primary-content"
                          href={`https://ipfs.infura.io/ipfs/${contractDetails?.tokenmetadata?.animation_url?.split("//")[1]}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {contractDetails?.tokenmetadata?.animation_url}
                        </a>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="stat-actions">
              <TokenMetaUpdate {...props} tokenMetadata={contractDetails.tokenmetadata} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContractStats;
