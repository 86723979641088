import React, { useState, useEffect } from "react";

import ContractStats from "./contractstats";
import { getChain, switchChain, targetChainId } from "../utils/web3";

const mxfContract = require("../contract/MxF.json");
const mxfAbi = mxfContract.abi;
const mxfAddress = require("../contract/address.json");

const adminAddresses = [
  "0x44011807660b00649e2ac6dF271D348806ced0aC",
  "0xe027488fc54732e38596f0e99320547cc3ad2eb7", //derek
  "0xb45eB7097Fef91eBF2ee238Fd4E44D8FE7C6C91E", //jon
  "0x620b330c2e1d32Ee40D912F82FbA270a857EA0dF", // doug
];

/**  address add_Dev = 0x44011807660b00649e2ac6dF271D348806ced0aC;
  address add_Artist = 0xe027488fc54732E38596F0E99320547Cc3ad2eB7;
  address add_DG = 0x620b330c2e1d32Ee40D912F82FbA270a857EA0dF;
  address add_JL = 0xb45eB7097Fef91eBF2ee238Fd4E44D8FE7C6C91E; */

const initialContractState = {
  name: "",
  symbol: "",
  salelive: false,
  minted: 0,
  total: 0,
  ethbalance: 0,
  price: 0,
  tokenuri: "",
  contracturi: "",
  tokenmetadata: "",
  contractmetadata: "",
};

const initialLoadState = {
  fetching: false,
  pendingRequest: false,
};

const Dashboard = (props) => {
  const { account, toastList, setToastList } = props;
  const adminIndex = adminAddresses.findIndex((address) => address.toLowerCase() === account?.address?.toLowerCase());
  const web3 = account?.web3;
  const w3mxf = new web3.eth.Contract(mxfAbi, mxfAddress);

  const [contractDetails, setContractDetails] = useState(initialContractState);

  const [loadStatus, setLoadStatus] = useState({ initialLoadState });

  const getContractDetails = async () => {
    setLoadStatus((prevState) => ({ ...prevState, fetching: true }));
    if (account.chainId !== targetChainId) {
      await switchChain();
    }
    if (account.chainId === targetChainId) {
      const name = await w3mxf.methods.name().call();
      const symbol = await w3mxf.methods.symbol().call();
      const minted = await w3mxf.methods.totalSupply().call();
      const total = await w3mxf.methods.MAX().call();
      const salelive = await w3mxf.methods.saleLive().call();
      const tokenuri = await w3mxf.methods.uri(1).call();
      // const contracturi = await w3mxf.methods.contractURI().call();

      const tokenurifetch = await fetch(`https://ipfs.io/ipfs/${tokenuri.split("//")[1]}`);
      const tokenmetadata = await tokenurifetch.json();
      // const contracturifetch = await fetch(`https://ipfs.infura.io/ipfs/${contracturi.split("//")[1]}`);
      //const contractmetadata = await contracturifetch.json();

      const price = web3.utils.fromWei(await w3mxf.methods.PRICE().call());
      const ethbalance = web3.utils.fromWei(await web3.eth.getBalance(mxfAddress));
      setContractDetails((prevState) => ({
        ...prevState,
        name,
        symbol,
        minted,
        total,
        ethbalance,
        price,
        salelive,
        tokenuri,

        tokenmetadata,
      }));
      setLoadStatus((prevState) => ({ ...prevState, fetching: false }));
    } else {
      setToastList([
        ...toastList,
        { type: "alert-error", status: "error", message: `Please switch your wallet network to ${getChain(targetChainId).name}` },
      ]);
    }
  };

  const contractProps = { contractDetails, setContractDetails, w3mxf, getContractDetails };

  useEffect(() => {
    getContractDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  if (adminIndex === -1)
    return (
      <div className="text-4xl font-black flex flex-col text-center">
        YOU ARE NOT AUTHORIZED<span>{account?.address}</span>
      </div>
    );
  else
    return (
      <div data-theme="maindark" className="min-h-screen container mx-auto">
        Network: <span className="uppercase">{getChain(account.chainId).name}</span>
        <div className="flex  justify-center">
          <ContractStats loadStatus={loadStatus} {...contractProps} {...props} />
        </div>
      </div>
    );
};

export default Dashboard;
