const getChain = (chainId) => {
  // chainId must be in hexadecimal numbers - 0x1 mainnet, 0x3 ropsten, 0x4 rinkeby
  switch (chainId) {
    case "0x1":
      return {
        name: "mainnet",
        etherscan: "https://etherscan.io/",
      };
    case "0x4":
      return {
        name: "rinkeby",
        etherscan: "https://rinkeby.etherscan.io/",
      };
    default:
      return {
        name: "unknown",
        etherscan: "",
      };
  }
};

const targetChainId = "0x1"; // chainId must be in hexadecimal numbers - 0x1 mainnet, 0x3 ropsten, 0x4 rinkeby

const switchChain = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: targetChainId }], // chainId must be in hexadecimal numbers - 0x1 mainnet, 0x3 ropsten, 0x4 rinkeby
    });
  } catch (err) {
    console.log("Error: " + err.message);
  } finally {
    let chainId = await window.ethereum.request({ method: "eth_chainId" });
    console.log("Network:", chainId);
    return chainId;
  }
};

export { getChain, targetChainId, switchChain };
