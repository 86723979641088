import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route } from "react-router-dom";
import Web3 from "web3";

import { targetChainId, switchChain } from "./utils/web3";

import Toast from "./components/toast";
import Dashboard from "./components/dashboard";
import Layout from "./layout";
import Mint from "./mint";

const initialAccountState = {
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: "",
  status: "",
};

const WalletButton = (props) => {
  const { account, connectWallet } = props;
  if (typeof window.ethereum !== "undefined") {
    return (
      <>
        {account.connected ? (
          <Dashboard {...props} />
        ) : (
          <div className="container mx-auto flex flex-col justify-center items-center ">
            <button className={`btn btn-error btn-lg btn-wide rounded-full ${account.connected ? "btn-disabled" : ""}`} onClick={connectWallet}>
              <span>
                {account.connected
                  ? "Connected " + String(account.address).substring(0, 5) + "..." + String(account.address).substring(38)
                  : "Connect Wallet"}
              </span>
            </button>
            {account.status}
          </div>
        )}
      </>
    );
  } else {
    console.log("no MM");

    return (
      <div className="container mx-auto flex flex-col justify-center items-center ">
        <button
          className="btn btn-error btn-lg btn-wide rounded-full"
          onClick={(e) => {
            window.open("https://metamask.io");
            throw new Error("MetaMask not installed");
          }}
        >
          <span>Install MetaMask</span>
        </button>
        {account.status}
      </div>
    );
  }
};

function App() {
  const [account, setAccount] = useState(initialAccountState);
  const [toastList, setToastList] = useState([]);
  const [toastProcessList, setToastProcessList] = useState([]);

  const connectWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        const provider = Web3.givenProvider;
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        const address = accounts[0];
        const web3 = new Web3(Web3.givenProvider || "http://127.0.0.1:8546");
        let chainId = await window.ethereum.request({ method: "eth_chainId" });
        if (chainId !== targetChainId) chainId = await switchChain();
        const status = `Connected: ${String(address).substring(0, 5) + "..." + String(address).substring(38)}`;
        setAccount((prevState) => ({ ...prevState, web3, provider, connected: true, address, chainId, status }));
      } catch (err) {
        const status = err.message;
        setAccount((prevState) => ({ ...prevState, status }));
        console.error(err.message);
      }
    }
  };

  const checkWalletConnected = useCallback(async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        const provider = Web3.givenProvider;
        const accounts = await window.ethereum.request({ method: "eth_accounts" });
        if (accounts.length > 0) {
          const address = accounts[0];
          const web3 = new Web3(Web3.givenProvider || "http://127.0.0.1:8546");
          let chainId = await window.ethereum.request({ method: "eth_chainId" });
          if (chainId !== targetChainId) chainId = await switchChain();
          const status = `Connected: ${String(address).substring(0, 5) + "..." + String(address).substring(38)}`;
          setAccount((prevState) => ({ ...prevState, web3, provider, connected: true, address, chainId, status }));
        }
      } catch (err) {
        const status = err.message;
        setAccount((prevState) => ({ ...prevState, status }));
        console.error(err.message);
      }
    }
  }, []);

  const disconnectWallet = useCallback(async () => {
    const { provider } = account;
    await provider?.disconnect?.();
    setAccount({ ...initialAccountState });
  }, [account]);

  useEffect(() => {
    checkWalletConnected();
  }, [checkWalletConnected]);

  useEffect(() => {
    if (account.provider?.on) {
      const provider = account.provider;
      const handleAccountsChanged = (accounts) => {
        if (accounts.length === 0) {
          disconnectWallet();
        } else if (accounts[0] !== account.address) {
          const status = `Connected: ${String(accounts[0]).substring(0, 5) + "..." + String(accounts[0]).substring(38)}`;
          setAccount((prevState) => ({ ...prevState, address: accounts[0], status }));
          console.log(accounts[0]);
        }
      };

      const handleChainChanged = (chainId) => {
        setAccount((prevState) => ({ ...prevState, chainId }));
        console.log(chainId);
      };
      const handleDisconnect = (error) => {
        disconnectWallet();
        console.log("Disconnected", error);
      };
      const handleConnect = (info) => {
        console.log(info);
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);
      provider.on("close", handleDisconnect);
      provider.on("connect", handleConnect);

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
          provider.removeListener("close", handleDisconnect);
          provider.removeListener("connect", handleConnect);
        }
      };
    }
  }, [account.address, account.provider, disconnectWallet]);

  const toastProps = { toastList, setToastList, toastProcessList, setToastProcessList };
  const accountProps = { account, setAccount, connectWallet };

  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Mint {...toastProps} {...accountProps} />} />
          <Route path="dashboard" element={<WalletButton connectWallet={connectWallet} {...accountProps} {...toastProps} />} />
        </Route>
      </Routes>
      <Toast toastList={toastList} toastProcessList={toastProcessList} />
    </div>
  );
}

export default App;
