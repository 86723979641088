import React, { useState } from "react";
import { targetChainId, switchChain, getChain } from "../../utils/web3";

const initialLoadState = {
  fetching: false,
  pendingRequest: false,
};

const PriceChange = (props) => {
  const { account, w3mxf, toastList, toastProcessList, setToastList, setToastProcessList, getContractDetails } = props;
  const web3 = account.web3;
  const [newPrice, setNewPrice] = useState({ input: "", pricewei: "" });
  const [loadStatus, setLoadStatus] = useState(initialLoadState);

  const handlePriceChange = (e) => {
    const { value } = e.target;
    const valuetoWei = web3.utils.toWei(Math.max(value, 0).toString());
    setNewPrice({ input: Math.max(value, 0), pricewei: valuetoWei });
  };

  const submitPriceChange = async (e) => {
    setLoadStatus((prevState) => ({ ...prevState, pendingRequest: true }));
    e.preventDefault();
    try {
      if (account.chainId !== targetChainId) await switchChain();
      if (account.chainId === targetChainId) {
        var gas;
        await w3mxf.methods
          .setPrice(newPrice.pricewei)
          .estimateGas({ to: w3mxf._address, from: account.address })
          .then((estGas) => (gas = parseInt((estGas * 110) / 100)))
          .catch((err) => {
            gas = 200000;
          });

        const tx = {
          to: w3mxf._address,
          from: account.address,
          gas: gas,

          data: w3mxf.methods.setPrice(newPrice.pricewei).encodeABI(),
        };

        var hashurl;
        const txn = await web3.eth.sendTransaction(tx).on("transactionHash", function (hash) {
          console.log("Transaction Hash", hash);

          hashurl = `<a href="${getChain(targetChainId).etherscan}tx/${hash}" target="_blank" className="link break-all">${hash}</a>`;

          setToastProcessList([
            ...toastProcessList,
            {
              type: "alert-info",
              status: "processing",
              id: "price",
              message: `Transaction Processing: ${hashurl}`,
            },
          ]);
        });
        if (txn.status) {
          setToastProcessList([
            ...toastProcessList.splice(
              toastProcessList.findIndex((e) => e.id === "price"),
              1
            ),
          ]);
          setToastList([...toastList, { type: "alert-success", status: "success", message: `Price change successful: ${hashurl}` }]);
          getContractDetails();
        }
      } else {
        setToastList([
          ...toastList,
          { type: "alert-error", status: "error", message: `Please switch your wallet network to ${getChain(targetChainId).name}` },
        ]);
      }
    } catch (err) {
      console.log(err);
      setToastList([...toastList, { type: "alert-error", status: "error", message: `${err.message}` }]);
      setToastProcessList([
        ...toastProcessList.splice(
          toastProcessList.findIndex((e) => e.id === "price"),
          1
        ),
      ]);
    } finally {
      setLoadStatus((prevState) => ({ ...prevState, pendingRequest: false }));
    }
  };

  return (
    <div>
      <label htmlFor="modal-pricechange" className=" btn btn-secondary rounded-lg">
        Change Price
      </label>
      <input type="checkbox" id="modal-pricechange" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative max-w-2xl border ">
          <label htmlFor="modal-pricechange" className="btn btn-sm btn-circle absolute right-2 top-2">
            ✕
          </label>
          <div className="flex  flex-col mt-10 ">
            <div className="flex flex-col lg:flex-row w-full justify-between items-center  mb-5">
              <h1 className="text-4xl font-bold">Price Change</h1>
              <button
                className={`btn btn-primary ${loadStatus.pendingRequest ? "btn-disabled" : ""}`}
                onClick={(e) => {
                  submitPriceChange(e);
                }}
              >
                {loadStatus.pendingRequest && (
                  <svg
                    role="status"
                    className="mr-2 w-6 h-6 text-info-content animate-spin fill-info"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                )}
                Submit Price Change
              </button>
            </div>

            <div className="flex flex-row   justify-between my-2">
              <div className="flex flex-row w-3/4 items-center">
                <div className="form-control w-full ">
                  <label className="input-group input-group-sm">
                    <span className="w-1/5 min-w-fit">New Price</span>
                    <input
                      step="0.001"
                      type="number"
                      name="price"
                      value={newPrice.input}
                      placeholder="New Price"
                      onChange={(e) => handlePriceChange(e)}
                      className="input input-bordered input-sm w-3/4"
                    />
                    <span>ETH</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceChange;
