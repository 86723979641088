import React, { useState, useEffect, useCallback } from "react";
import Web3 from "web3";

import { targetChainId, switchChain, getChain } from "./utils/web3";

import mxfpass from "./images/final/MxF_Pass_480.mov";
import mxfgif from "./images/final/Logo_60percent.gif";


const mxfContract = require("./contract/MxF.json");
const mxfAbi = mxfContract.abi;
const mxfAddress = require("./contract/address.json");

const { para1, para2, para3 } = require("./data/mintpara.json");

const MintButton = (props) => {
  const { account, connectWallet, mintPass } = props;
  if (typeof window.ethereum !== "undefined") {
    return (
      <button
        className="btn btn-primary border w-full border-white outline outline-black rounded-none"
        onClick={(e) => (account?.connected ? mintPass(e) : connectWallet(e))}
      >
        <span>{account?.connected ? "Mint Now" : "Connect Wallet"}</span>
      </button>
    );
  } else {
    console.log("no MM");

    return (
      <button
        className="btn btn-error "
        onClick={(e) => {
          window.open("https://metamask.io");
          throw new Error("MetaMask not installed");
        }}
      >
        <span>Install MetaMask</span>
      </button>
    );
  }
};

const initialLoadState = {
  fetching: false,
  pendingRequest: false,
};
const Mint = (props) => {
  const { account, setAccount, toastList, toastProcessList, setToastList, setToastProcessList, connectWallet } = props;
  const [loadStatus, setLoadStatus] = useState(initialLoadState);
  const [mintDetails, setMintDetails] = useState({
    total: 0,
    minted: 0,
    price: 0,
  });

  const mintPass = async (e) => {
    setLoadStatus((prevState) => ({ ...prevState, pendingRequest: true }));
    e.preventDefault();

    const web3 = account.web3;
    const w3mxf = new web3.eth.Contract(mxfAbi, mxfAddress);
    try {
      if (account.chainId !== targetChainId) await switchChain();
      if (account.chainId === targetChainId) {
        const saleLive = await w3mxf.methods.saleLive().call();

        if (!saleLive) {
          setToastList([...toastList, { type: "alert-error", status: "error", message: `Sale has not started` }]);
        } else {
          var gas;
          const price = await w3mxf.methods.PRICE().call();
          await w3mxf.methods
            .mint()
            .estimateGas({ to: w3mxf._address, from: account.address, value: price })
            .then((estGas) => (gas = parseInt((estGas * 110) / 100)))
            .catch((err) => {
              gas = 200000;
            });

          const tx = {
            to: w3mxf._address,
            from: account.address,
            gas: gas,
            value: price,
            data: w3mxf.methods.mint().encodeABI(),
          };

          var hashurl;
          const txn = await web3.eth.sendTransaction(tx).on("transactionHash", function (hash) {
            console.log("Transaction Hash", hash);

            hashurl = `<a href="${getChain(targetChainId).etherscan}tx/${hash}" target="_blank" className="link break-all">${hash}</a>`;

            setToastProcessList([
              ...toastProcessList,
              {
                type: "alert-info",
                status: "processing",
                id: "mint",
                message: `Transaction Processing: ${hashurl}`,
              },
            ]);
          });
          if (txn.status) {
            setToastProcessList([
              ...toastProcessList.splice(
                toastProcessList.findIndex((e) => e.id === "mint"),
                1
              ),
            ]);
            setToastList([...toastList, { type: "alert-success", status: "success", message: `Mint successful: ${hashurl}` }]);
            getMintDetails();
          }
        }
      } else {
        setToastList([
          ...toastList,
          { type: "alert-error", status: "error", message: `Please switch your wallet network to ${getChain(targetChainId).name}` },
        ]);
      }
    } catch (err) {
      console.log(err);
      setToastList([...toastList, { type: "alert-error", status: "error", message: `${err.message}` }]);
      setToastProcessList([
        ...toastProcessList.splice(
          toastProcessList.findIndex((e) => e.id === "mint"),
          1
        ),
      ]);
    } finally {
      setLoadStatus((prevState) => ({ ...prevState, pendingRequest: false }));
    }
  };

  const getMintDetails = async () => {
    setLoadStatus((prevState) => ({ ...prevState, fetching: true }));
    if (typeof window.ethereum !== "undefined") {
      var chainId;
      var web3 = null;
      if (account.chainId) chainId = account.chainId;
      else chainId = await window.ethereum.request({ method: "eth_chainId" });

      if (chainId !== targetChainId) {
        await switchChain();
      }
      if (chainId === targetChainId) {
        if (account.web3) {
          web3 = account.web3;
        } else {
          web3 = new Web3(Web3.givenProvider || "http://127.0.0.1:8546");
        }
        const w3mxf = new web3.eth.Contract(mxfAbi, mxfAddress);

        const minted = await w3mxf.methods.totalSupply().call();
        const total = await w3mxf.methods.MAX().call();

        const price = web3.utils.fromWei(await w3mxf.methods.PRICE().call());

        setMintDetails((prevState) => ({
          ...prevState,
          minted,
          total,
          price,
        }));
        setLoadStatus((prevState) => ({ ...prevState, fetching: false }));
      } else {
        const web3 = new Web3("https://mainnet.infura.io/v3/94d0e160138c4b2b8dda74c503432245"); // change for mainnet
        const w3mxf = new web3.eth.Contract(mxfAbi, mxfAddress);

        const minted = await w3mxf.methods.totalSupply().call();
        const total = await w3mxf.methods.MAX().call();

        const price = web3.utils.fromWei(await w3mxf.methods.PRICE().call());

        setMintDetails((prevState) => ({
          ...prevState,
          minted,
          total,
          price,
        }));
        setLoadStatus((prevState) => ({ ...prevState, fetching: false }));
      }
    } else {
      const web3 = new Web3(Web3.givenProvider || "https://mainnet.infura.io/v3/94d0e160138c4b2b8dda74c503432245"); // change for mainnet
      const w3mxf = new web3.eth.Contract(mxfAbi, mxfAddress);

      const minted = await w3mxf.methods.totalSupply().call();
      const total = await w3mxf.methods.MAX().call();

      const price = web3.utils.fromWei(await w3mxf.methods.PRICE().call());

      setMintDetails((prevState) => ({
        ...prevState,
        minted,
        total,
        price,
      }));
      setLoadStatus((prevState) => ({ ...prevState, fetching: false }));
    }
  };

  useEffect(() => {
    getMintDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <div data-theme="main" className="font-opensans bg-base-100 text-white min-h-screen flex flex-col ">
      <div className=" flex w-full bg-none md:bg-mxf-banner lg:bg-mxf-banner-lg bg-no-repeat lg:bg-center  bg-origin bannerh bg-cover items-center ">
        <div className="flex flex-col my-auto md:flex-row   items-center md:container mx-auto  md:-translate-x-5  px-0 w-full h-full ">
          <div className=" lg:w-1/2  h-96 md:h-fit  place-self-center  lg:-translate-x-10  ">
            <video autoPlay muted loop className="h-full hidden sm:flex ">
              <source src={mxfpass} type="video/mp4" />
            </video>

            <img src={mxfgif} alt="passgif" className="h-full sm:hidden" />
          </div>
          <div className="w-full lg:w-3/4  flex   bg-mxf-banner bg-origin bg-cover md:bg-none h-full  items-center  ">
            <div className="flex  w-full lg:h-fit  justify-center md:justify-start   ">
              <div className="flex flex-col md:pl-20 ">
                <span className=" font-opensans font-black text-4xl tracking-widest mb-5 text-center md:text-left">MXF PASS</span>
                <span className=" font-earthtitle text-lg tracking-widest lowercase text-center md:text-left">myitchyfinger</span>
                <span className="font-earthtitle text-lg tracking-widest lowercase text-center md:text-left">Collaboration Pass</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 bg-white text-black w-full max-h-fit lg:max-h-fit">
        <div className="flex flex-col w-full container mx-auto  lg:items-center lg:justify-between  bg-white text-black ">
          <div className="flex flex-col w-full h-full  items-center lg:items-start  my-5  ">
            <h1 className="font-black text-4xl tracking-wider mb-2 uppercase text-center lg:text-left">Join Us</h1>
            <div className="flex flex-col sm:flex-row text-base tracking-widest font-semibold text-gray-400   justify-center lg:justify-start text-center lg:text-left lg:textjustify w-full">
              MxF: <span className="ml-1">M: MYITCHYFINGER</span>
              <span className="mx-5">x: COLLABORATION</span>
              <span className=""> F: FRIENDS</span>
            </div>
          </div>

          <div className="flex  flex-col lg:flex-row h-full items-center lg:items-start  justify-center lg:justify-between mt-0 mb-5 w-full  ">
            <div className="inline-flex flex-col w-full lg:w-2/3 ">
              <span className=" flex text-lg tracking-widest text-justify  mx-1 lg:mx-0 ">{para1}</span>
              <span className=" flex text-lg tracking-widest text-justify  mx-1 lg:mx-0 my-5">{para2}</span>
              <span className=" flex text-lg tracking-widest text-justify  mx-1 lg:mx-0">{para3}</span>
            </div>
            <div className="flex flex-col items-center my-10 lg:my-0 w-fit ">
              <div>
                {loadStatus.fetching ? (
                  <div className="w-36 bg-base-content h-10 rounded-md animate-pulse "></div>
                ) : (
                  <div className="font-black text-4xl tracking-wider ">
                    {mintDetails.minted}/{mintDetails.total ? mintDetails.total : 888}
                  </div>
                )}
              </div>

              <div>
                {loadStatus.fetching ? (
                  <div className="w-36 bg-base-content h-10 rounded-md animate-pulse my-5 "></div>
                ) : (
                  <div className=" text-lg tracking-widest uppercase my-5">{mintDetails.price} ETH / PASS</div>
                )}
              </div>

              <MintButton account={account} connectWallet={connectWallet} mintPass={mintPass} />
            </div>
          </div>
        </div>
      </div>
      <div className="uppercase tracking-widest my-4 ml-10">myitchyfinger pte ltd</div>


    </div>
  );
};

export default Mint;
