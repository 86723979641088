import React, { useState, useEffect } from "react";
import { create } from "ipfs-http-client";
import { targetChainId, switchChain, getChain } from "../../utils/web3";

let ipfsInfura = null;

const initialLoadState = {
  fetching: false,
  pendingRequest: false,
};

const TokenMetaUpdate = (props) => {
  const { account, w3mxf, toastList, toastProcessList, setToastList, setToastProcessList, getContractDetails, tokenMetadata } = props;
  const web3 = account.web3;
  const [tokenMeta, setTokenMeta] = useState({
    name: { input: tokenMetadata.name || "", value: tokenMetadata.name || "", include: true },
    description: { input: tokenMetadata.description || "", value: tokenMetadata.description || "", include: true },
    image: { input: tokenMetadata.image || "", value: tokenMetadata.image || "", include: true },
    animation_url: {
      input: tokenMetadata.animation_url || "",
      value: tokenMetadata.animation_url || "",
      include: tokenMetadata.animation_url ? true : false,
    },
    external_url: {
      input: tokenMetadata.external_url || "",
      value: tokenMetadata.external_url || "",
      include: tokenMetadata.external_url ? true : false,
    },
  });
  const [tokenImage, setTokenImage] = useState({
    image: { filename: "", data: "", file: "" },
    animation_url: { filename: "", data: "", file: "" },
  });
  const [imageURLs, setImageURLs] = useState({
    image: { old: "", new: "" },
    animation_url: { old: "", new: "", oldkey: "animationurlold", newkey: "animationurlnew" },
  });
  const [loadStatus, setLoadStatus] = useState(initialLoadState);

  async function startInfura() {
    if (ipfsInfura) {
      console.log("IPFS Infura already started");
    } else {
      try {
        console.time("IPFS Infura Started");
        ipfsInfura = await create("https://ipfs.infura.io:5001/api/v0");
        console.timeEnd("IPFS Infura Started");
      } catch (error) {
        console.error("IPFS Infura init error:", error);
        ipfsInfura = null;
      }
    }
  }

  const handleImgUpload = async (e) => {
    const { name, files } = e.target;
    const imagedata = { ...tokenImage };
    if (files.length > 0) {
      imagedata[name].filename = files[0].name;
      imagedata[name].file = files[0];
      imagedata[name].data = URL.createObjectURL(files[0]);
      setTokenImage(imagedata);
    }
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const newmetadata = { ...tokenMeta };
    switch (name) {
      case "name":
        newmetadata.name.input = value;
        newmetadata.name.value = value;
        setTokenMeta(newmetadata);
        break;
      case "description":
        newmetadata.description.input = value;
        newmetadata.description.value = value;
        setTokenMeta(newmetadata);
        break;
      case "website":
        newmetadata.external_url.input = value;
        newmetadata.external_url.value = value;
        setTokenMeta(newmetadata);
        break;

      default:
        break;
    }
  };

  const handleOptionalItems = async (e) => {
    const { name } = e.target;
    const metadata = { ...tokenMeta };
    metadata[name].include = !metadata[name].include;

    setTokenMeta(metadata);
  };

  const checkInputs = async () => {
    const metadata = { ...tokenMeta };
    var error = { status: false, message: "" };
    // name, desc and image are compulsory fields.
    if (!metadata.name.input || !metadata.name.value) error = { status: true, message: `Error with Name input` }; //name
    if (!metadata.description.input || !metadata.description.value) error = { status: true, message: `Error with Description input` }; //desc
    if (!tokenImage.image.data && (!metadata.image.input || !metadata.image.value)) error = { status: true, message: `Error with Image input` }; // image

    //website and animation_url are optional

    if (metadata.animation_url.include && !tokenImage.animation_url.data && (!metadata.animation_url.input || !metadata.animation_url.value))
      error = { status: true, message: `Error with Animation input` }; //include but no data
    if (metadata.external_url.include && (!metadata.external_url.value || !metadata.external_url.input))
      error = { status: true, message: `Error with Website input` };
    return error;
  };

  const updateTokenMetadata = async (e) => {
    setLoadStatus((prevState) => ({ ...prevState, pendingRequest: true }));
    e.preventDefault();
    var ipfsURIs = {};

    try {
      //check inputs for errors
      const checkinputerror = await checkInputs();
      if (checkinputerror.status === true) setToastList([...toastList, { type: "alert-error", status: "error", message: checkinputerror.message }]);
      else {
        //upload image (and animation_url if any) to IPFS to get CID
        await startInfura();
        const options = {
          wrapWithDirectory: false,
          progress: (prog, path) => console.log(`received: ${prog} / ${path} `),
        };
        if (tokenImage.image.data) {
          const file = {
            path: tokenImage.image.filename,
            content: tokenImage.image.file,
          };
          const result = await ipfsInfura.add(file, options);
          ipfsURIs["image"] = `ipfs://${result.cid.toString()}`;
        } else ipfsURIs["image"] = tokenMeta.image.value;

        if (tokenMeta.animation_url.include && tokenImage.animation_url.data) {
          const file = {
            path: tokenImage.animation_url.filename,
            content: tokenImage.animation_url.file,
          };
          const result = await ipfsInfura.add(file, options);
          ipfsURIs["animation_url"] = `ipfs://${result.cid.toString()}`;
        } else if (tokenMeta.animation_url.include && !tokenImage.animation_url.data) ipfsURIs["animation_url"] = tokenMeta.animation_url.value;

        //populate new metadata json object
        const newTokenMeta = {
          name: tokenMeta.name.value,
          description: tokenMeta.description.value,
          image: ipfsURIs.image,
          background_color: "000000",
        };
        if (tokenMeta.external_url.include) newTokenMeta["external_url"] = tokenMeta.external_url.value;
        if (tokenMeta.animation_url.include) newTokenMeta["animational_url"] = ipfsURIs.animation_url;

        //upload new metadata to IPFS
        const file = {
          path: `${newTokenMeta.name}.json`,
          content: JSON.stringify(newTokenMeta, null, 2),
        };
        const result = await ipfsInfura.add(file, options);
        ipfsURIs["metadata"] = `ipfs://${result.cid.toString()}`;

        //set new uri to the new CID hash.
        if (account.chainId !== targetChainId) await switchChain();
        if (account.chainId === targetChainId) {
          var gas;
          await w3mxf.methods
            .setURI(ipfsURIs.metadata)
            .estimateGas({ to: w3mxf._address, from: account.address })
            .then((estGas) => (gas = parseInt((estGas * 110) / 100)))
            .catch((err) => {
              gas = 200000;
            });

          const tx = {
            to: w3mxf._address,
            from: account.address,
            gas: gas,

            data: w3mxf.methods.setURI(ipfsURIs.metadata).encodeABI(),
          };

          var hashurl;
          const txn = await web3.eth.sendTransaction(tx).on("transactionHash", function (hash) {
            console.log("Transaction Hash", hash);

            hashurl = `<a href="${getChain(targetChainId).etherscan}tx/${hash}" target="_blank" className="link break-all">${hash}</a>`;

            setToastProcessList([
              ...toastProcessList,
              {
                type: "alert-info",
                status: "processing",
                id: "tokenuri",
                message: `Transaction Processing: ${hashurl}`,
              },
            ]);
          });
          if (txn.status) {
            setToastProcessList([
              ...toastProcessList.splice(
                toastProcessList.findIndex((e) => e.id === "tokenuri"),
                1
              ),
            ]);
            setToastList([...toastList, { type: "alert-success", status: "success", message: `Token URI change successful: ${hashurl}` }]);
            getContractDetails();
          }
        } else {
          setToastList([
            ...toastList,
            { type: "alert-error", status: "error", message: `Please switch your wallet network to ${getChain(targetChainId).name}` },
          ]);
        }
      }
    } catch (err) {
      setToastList([...toastList, { type: "alert-error", status: "error", message: `${err.message}` }]);
      setToastProcessList([
        ...toastProcessList.splice(
          toastProcessList.findIndex((e) => e.id === "contracturi"),
          1
        ),
      ]);
      console.log(err);
    } finally {
      setLoadStatus((prevState) => ({ ...prevState, pendingRequest: false }));
      ipfsInfura = null;
    }
  };

  useEffect(() => {
    const oldMeta = { ...tokenMetadata };
    setTokenMeta({
      name: { input: oldMeta.name || "", value: oldMeta.name || "", include: true },
      description: { input: oldMeta.description || "", value: oldMeta.description || "", include: true },
      image: { input: oldMeta.image || "", value: oldMeta.image || "", include: true },
      animation_url: { input: oldMeta.animation_url || "", value: oldMeta.animation_url || "", include: oldMeta.animation_url ? true : false },
      external_url: { input: oldMeta.external_url || "", value: oldMeta.external_url || "", include: oldMeta.external_url ? true : false },
    });
  }, [tokenMetadata]);

  useEffect(() => {
    const imgURLs = { ...imageURLs };
    const meta = {};
    meta.image = tokenMeta.image;
    meta.animation_url = tokenMeta.animation_url;
    if (meta.image.value) imgURLs.image.old = `https://ipfs.io/ipfs/${meta.image?.value?.split("//")[1]}`;
    if (meta.animation_url.value) {
      imgURLs.animation_url.old = `https://ipfs.io/ipfs/${meta.animation_url?.value?.split("//")[1]}`;
      imgURLs.animation_url.oldkey = `${imgURLs.animation_url.oldkey}1`;
    }

    setImageURLs(imgURLs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenMeta.animation_url, tokenMeta.image]);

  useEffect(() => {
    const imgURLs = { ...imageURLs };
    imgURLs.image.new = tokenImage.image.data;
    imgURLs.animation_url.new = tokenImage.animation_url.data;
    imgURLs.animation_url.newkey = `${imgURLs.animation_url.newkey}1`;
    setImageURLs(imgURLs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenImage]);

  return (
    <div>
      <label htmlFor="modal-tokenmetaupdate" className=" btn btn-secondary rounded-lg">
        Update Token Metadata
      </label>
      <input type="checkbox" id="modal-tokenmetaupdate" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative max-w-6xl h-fit border ">
          <label htmlFor="modal-tokenmetaupdate" className="btn btn-sm btn-circle absolute right-2 top-2">
            ✕
          </label>
          <div className="flex  flex-col mt-10 ">
            <div className="flex flex-col lg:flex-row w-full justify-between items-center  mb-5">
              <h1 className="text-4xl font-bold">Token Metadata Update</h1>
              <button
                className={`btn btn-primary ${loadStatus.pendingRequest ? "btn-disabled" : ""}`}
                onClick={(e) => {
                  updateTokenMetadata(e);
                }}
              >
                {loadStatus.pendingRequest && (
                  <svg
                    role="status"
                    className="mr-2 w-6 h-6 text-info-content animate-spin fill-info"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                )}
                Update Token Metadata
              </button>
            </div>

            <div className="flex flex-row w-full items-center ">
              <div className="form-control w-full ">
                {tokenMeta.name && (
                  <label className="input-group input-group-sm my-2 ">
                    <span className="w-1/5 min-w-fit">Name</span>
                    <input
                      type="text"
                      name="name"
                      value={tokenMeta.name.input}
                      placeholder="Collection Name"
                      onChange={(e) => handleInputChange(e)}
                      className="input input-bordered input-sm w-3/4"
                    />
                  </label>
                )}
                {tokenMeta.description && (
                  <label className="input-group input-group-sm my-2">
                    <span className="w-1/5 min-w-fit">Description</span>
                    <textarea
                      type="text"
                      name="description"
                      value={tokenMeta.description.input}
                      placeholder="Collection Description"
                      onChange={(e) => handleInputChange(e)}
                      className="textarea textarea-bordered h-32 w-3/4"
                    />
                  </label>
                )}
                {tokenMeta.external_url && (
                  <label className="input-group input-group-sm my-2  items-center">
                    <span className="w-1/5 min-w-fit">Website</span>
                    <input
                      type="url"
                      name="website"
                      value={tokenMeta.external_url.input}
                      placeholder="Collection Website"
                      onChange={(e) => handleInputChange(e)}
                      className="input input-bordered input-sm w-3/4"
                    />
                    <label className="label cursor-pointer">
                      <span className="label-text">Include in Metadata</span>
                      <input
                        name="external_url"
                        type="checkbox"
                        className="checkbox checkbox-primary"
                        value={tokenMeta.external_url.include}
                        onChange={(e) => handleOptionalItems(e)}
                        checked={tokenMeta.external_url.include}
                      />
                    </label>
                  </label>
                )}
                {tokenMeta.image && (
                  <>
                    <label className="input-group input-group-sm my-2 ">
                      <span className="w-1/5 min-w-fit">Image URL</span>
                      <input
                        type="text"
                        name="image"
                        value={tokenMeta.image.input}
                        placeholder="Token Image URL (jpeg, png, gif etc)"
                        className="input input-bordered input-sm w-3/4"
                        readOnly
                        disabled
                      />
                    </label>

                    <div className="flex flex-row justify-between ">
                      <div className=" flex flex-col ">
                        <span className=" text-sm w-36 ">Current Image</span>
                        <div className="w-36 h-36 ">
                          <img src={imageURLs.image.old} alt="currenttokenimage" />
                        </div>
                      </div>
                      <div className=" flex flex-col ">
                        <span className=" text-sm w-36 ">New Image</span>
                        <div className="w-36 h-36 ">
                          <img src={imageURLs.image.new} alt="newtokenimage" />
                        </div>
                      </div>

                      <div className=" flex flex-col  items-center text-center  ">
                        <div className=" text-center flex flex-row align-middle ">
                          <label htmlFor="image" className="btn btn-primary">
                            Select new image (if changing)
                          </label>
                          <input hidden id="image" type="file" name="image" accept="image/*" onChange={(e) => handleImgUpload(e)} />
                        </div>
                        <span className="items-center flex ">{tokenImage.image.filename}</span>
                      </div>
                    </div>
                  </>
                )}
                {tokenMeta.animation_url && (
                  <>
                    <label className="input-group input-group-sm my-2 ">
                      <span className="w-1/5 min-w-fit">Animation URL </span>
                      <input
                        type="text"
                        name="animationurl"
                        value={tokenMeta.animation_url.input}
                        placeholder="Animation URL (mp4, glb etc)"
                        className="input input-bordered input-sm w-3/4"
                        readOnly
                        disabled
                      />
                    </label>
                    <div className="flex flex-row justify-between ">
                      <div className=" flex flex-col ">
                        <span className=" text-sm w-36 ">Current Animation</span>
                        <div className="w-36 h-36 ">
                          <video autoPlay muted loop id="oldanimation" key={imageURLs.animation_url.oldkey}>
                            <source src={imageURLs.animation_url.old} type="video/mp4" />
                          </video>
                        </div>
                      </div>
                      <div className=" flex flex-col ">
                        <span className=" text-sm w-36 ">New Animation</span>
                        <div className="w-36 h-36 ">
                          <video autoPlay muted loop id="newanimation" key={imageURLs.animation_url.newkey}>
                            <source src={imageURLs.animation_url.new} type="video/mp4" />
                          </video>
                        </div>
                      </div>

                      <div className=" flex flex-col  items-center text-center  ">
                        <label className="label cursor-pointer">
                          <span className="label-text mr-2">Include in Metadata</span>
                          <input
                            name="animation_url"
                            type="checkbox"
                            className="checkbox checkbox-primary"
                            value={tokenMeta.animation_url.include}
                            onChange={(e) => handleOptionalItems(e)}
                            checked={tokenMeta.animation_url.include}
                          />
                        </label>
                        <div className=" text-center flex flex-row align-middle ">
                          <label htmlFor="animation_url" className="btn btn-primary">
                            Select new file (if changing)
                          </label>
                          <input hidden id="animation_url" type="file" name="animation_url" accept="video/*" onChange={(e) => handleImgUpload(e)} />
                        </div>
                        <span className="items-center flex ">{tokenImage.animation_url.filename}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenMetaUpdate;
